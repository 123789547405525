// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-templates-document-tsx": () => import("./../src/components/templates/document.tsx" /* webpackChunkName: "component---src-components-templates-document-tsx" */),
  "component---src-components-templates-article-tsx": () => import("./../src/components/templates/article.tsx" /* webpackChunkName: "component---src-components-templates-article-tsx" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-tsx": () => import("./../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-documents-tsx": () => import("./../src/pages/documents.tsx" /* webpackChunkName: "component---src-pages-documents-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-members-tsx": () => import("./../src/pages/members.tsx" /* webpackChunkName: "component---src-pages-members-tsx" */)
}

