module.exports = [{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":"50","icon":false,"className":"custom-class","maintainCase":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"manicreator.com","short_name":"manicreator.com","start_url":"/","background_color":"#bf0f58","theme_color":"#bf0f58","display":"minimal-ui","icon":"src/images/fabicon-256.png"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
